import React, {useState, useEffect, useCallback} from 'react';
import {View, ScrollView} from 'react-native';
import {Badge, Divider} from 'react-native-paper';
import moment from 'moment';
import _, {replace} from 'lodash';

import sy from '~/styles';
import {
  Text,
  Pressable,
  Link,
  FormTextInput,
  FormNumberInput,
  Grouping,
  Calendar,
  TimeSlots,
  Select,
  RadioButton,
  Dialog,
  PrimaryButton,
  ServiceRequestStatusIcon,
  InputChat,
} from '~/components/controls';
import {
  Row as GroupingRow,
  EditDialog as GroupingRowEdit,
} from '~/components/controls/Grouping';
import {LessorMessage} from '~/components/shared';
import {
  RentalClassActionsheet,
  RentalCarActionsheet,
} from '~/components/shared/Actionsheets';
import {InvoiceUploadDialog} from '~/components/shared/Dialogs';

import {serviceRequestLastFetch} from '~/actions';
import Format from '~/lib/format';
import {
  rob as robApi,
  service_request as serviceRequestApi,
  fleet as fleetApi,
} from '~/api/private';
import {address as addressApi} from '~/api/public';
import {
  REPLACEMENT_REASON,
  REPLACEMENT_REASON_TITLE,
  FLEET_REPLACEMENT_REASON,
  FLEET_REPLACEMENT_REASON_TITLE,
  ROB_REPLACEMENT_RENTAL_CHARGE_CODE,
  ROB_REPLACEMENT_KM_SURCHARGE_CODE,
  ROB_REPLACEMENT_FREE_KM_CODE,
  ROB_REPLACEMENT_STAGE,
  ROB_REPLACEMENT_STAGE_TITLE,
} from '~/types/replacement';
import {
  isLessorStatus,
  isRobTaskApproved,
  isRobTaskCancelled,
  canInvoice,
  isNotInvoiced,
  isArchived,
  isNotReadyForInvoicing,
  ROB_ERROR_TRANSLATIONS,
  ROB_NOT_INVOICED_STATUS_LABELS,
  ROB_STATUSES,
  ROB_TASK_STATUS,
} from '~/types/rob';
import {STATUS_CODES, STATUS_DESCRIPTIONS} from '~/types/statuses';
import {setAppIsLoading, setAppDoneLoading} from '~/actions';
import {useDispatch, useSelector} from '~/lib/hooks';
import withDimensions from '~/components/hoc/with-dimensions';

import EventIcon from '~/images/md-icons/event/materialicons/24px.svg';
import PhoneIcon from '~/images/md-icons/phone/materialicons/24px.svg';
import HomeFilledIcon from '~/images/md-icons/home_filled/materialicons/24px.svg';
import CarRentalIcon from '~/images/md-icons/car_rental/materialicons/24px.svg';
import LocalOfferIcon from '~/images/md-icons/local_offer/materialicons/24px.svg';
import AssignmentIcon from '~/images/md-icons/assignment/materialicons/24px.svg';
import DateRangeIcon from '~/images/md-icons/date_range/materialicons/24px.svg';
import AddRoadIcon from '~/images/md-icons/add_road/materialicons/24px.svg';
import SyncAltIcon from '~/images/md-icons/sync_alt/materialicons/24px.svg';
import LocalGasStationIcon from '~/images/md-icons/local_gas_station/materialicons/24px.svg';
import GppBadIcon from '~/images/md-icons/gpp_bad/materialicons/24px.svg';
import ChatIcon from '~/images/md-icons/chat/materialicons/24px.svg';
import ErrorIcon from '~/images/md-icons/error/materialicons/24px.svg';
import InsertInvitationIcon from '~/images/md-icons/insert_invitation/materialicons/24px.svg';
import AssignmentReturnIcon from '~/images/md-icons/assignment_return/materialicons/24px.svg';
import AssignmentTurnedInIcon from '~/images/md-icons/assignment_turned_in/materialicons/24px.svg';
import ReceiptIcon from '~/images/md-icons/receipt/materialicons/24px.svg';
import BlockIcon from '~/images/md-icons/block/materialicons/24px.svg';
import DescriptionIcon from '~/images/md-icons/description/materialicons/24px.svg';
import BusinessIcon from '~/images/md-icons/business/materialicons/24px.svg';
import RefreshIcon from '~/images/md-icons/refresh/materialicons/24px.svg';
import WarningLargeIcon from '~/images/md-icons/warning/materialicons/24px.svg';
import DiscountIcon from '~/images/md-icons/discount/materialicons/24px.svg';
import FileOpenIcon from '~/images/md-icons/file_open/materialicons/24px.svg';
import AccountCircleIcon from '~/images/md-icons/account_circle/materialicons/24px.svg';
import PersonIcon from '~/images/md-icons/person/materialicons/24px.svg';

const INVOICE_DISCOUNT_LABELS = {
  DEALER_FEE: 'Transactiekosten',
  DEALER_REPLACEMENT_SERVICE_PERCENTAGE: 'Administratiekosten',
  DEALER_REPLACEMENT_EXPENSE_PERCENTAGE: 'Bijdrage coulance-/garantiefonds',
};

const INVOICE_DISCOUNT_ORDER = [
  'SLA_REPLACEMENT_INVOICE_DISCOUNT',
  'DEALER_REPLACEMENT_SERVICE_PERCENTAGE',
  'DEALER_REPLACEMENT_EXPENSE_PERCENTAGE',
  'DEALER_FEE',
];

const INVOICE_COMPONENTS_HEADER_HEIGHT_IN_PX = 64;
const INVOICE_COMPONENTS_FOOTER_HEIGHT_IN_PX = 51;

const pickDeep = (acc, value) => {
  const result = {};
  for (const key of Object.keys(value)) {
    if (_.isObject(value[key])) {
      result[key] = pickDeep(acc[key] ?? {}, value[key]);
    } else if (
      key === 'location' /* location is a special case */ ||
      !_.isNil(value[key])
    ) {
      result[key] = value[key];
    }
  }

  return {
    ...acc,
    ...result,
  };
};

const PdfViewerLink = ({invoice, request_id}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      let pdf;
      if (invoice?.bahnkick_can_invoice) {
        if (invoice?.fleet_request) {
          pdf = await fleetApi.invoice_pdf(request_id);
        } else {
          pdf = await robApi.invoice_pdf(request_id);
        }
      } else {
        pdf = await serviceRequestApi.invoice_pdf(request_id);
      }

      setData(pdf);
    };

    fetch();
  }, [invoice, request_id]);

  return (
    <Link
      style={sy.underline}
      onPress={() => {
        var iframe = `<div onclick="window.close();" style='position: absolute;background-color: #fff; padding: 4px;'><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></div><iframe width='100%' height='100%' src="${URL.createObjectURL(
          data,
        )}"></iframe>`;
        var x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
      }}>
      Bekijk factuur (PDF)
    </Link>
  );
};

const LessorPhone = ({lessor, style}) => {
  let phone = lessor?.phone?.rental ?? lessor?.phone?.general;
  if (lessor?.sublessor?.phone) {
    phone = lessor.sublessor.phone.rental ?? lessor.sublessor.phone.general;
  }

  return <Text style={style}>{phone}</Text>;
};

const RentalClassText = ({
  style,
  rental_class,
  rental_classes,
  contract_rental_class = null,
  rental_car_class = null,
}) => (
  <>
    {rental_class ? (
      <Text style={style}>
        {rental_class}-
        {
          rental_classes?.find((item) => item.code === rental_class)
            ?.description
        }
        {rental_class === contract_rental_class &&
        rental_class === rental_car_class ? (
          <>&nbsp;(contract&nbsp;&amp;&nbsp;inzet)</>
        ) : rental_class === contract_rental_class ? (
          <>&nbsp;(contract)</>
        ) : rental_class === rental_car_class ? (
          <>&nbsp;(inzet)</>
        ) : (
          <></>
        )}
      </Text>
    ) : (
      <></>
    )}
  </>
);

const LineItem = ({
  icon,
  title,
  price,
  subtitle,
  subtitleColor,
  topMost,
  allowed = true,
  disabled = false,
  cancelled = false,
  editDialog,
  initialValues,
  onValidate,
  onChange,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [errors, setErrors] = useState(null);
  const [changes, setChanges] = useState({});

  useEffect(() => {
    setChanges(initialValues);
  }, [initialValues, isEditing]);

  return (
    <>
      <View
        style={[sy['flex-row'], (!allowed || disabled) && sy['view-disabled']]}>
        <View style={[sy['py-4'], sy['pl-4'], sy['pr-8']]}>{icon}</View>
        <View style={[sy['flex-1']]}>
          {!topMost && <Divider style={sy['bg-gray95']} />}
          <View style={[sy['py-4'], sy['pr-4']]}>
            <Pressable
              disabled={!allowed || disabled}
              onPress={() => setEditing(true)}>
              <View style={[sy['flex-row'], sy['justify-between']]}>
                <Text>{title}</Text>
                <Text
                  style={[
                    sy.smallRegular,
                    cancelled ? sy['line-through'] : null,
                  ]}>
                  {allowed && _.isNumber(price) ? Format.price(price) : <></>}
                </Text>
              </View>
              <Link
                disabled={!allowed || disabled}
                textStyle={[
                  sy.smallRegular,
                  subtitleColor ? {color: subtitleColor} : sy['text-lightgray'],
                ]}>
                {allowed ? (
                  price !== null && price !== undefined ? (
                    subtitle ?? <>Wijzigen</>
                  ) : (
                    <>Opgeven</>
                  )
                ) : (
                  <>Niet toegestaan</>
                )}
              </Link>
            </Pressable>
          </View>
        </View>
      </View>
      {editDialog && (
        <Dialog
          visible={isEditing}
          title={title}
          onDismiss={() => setEditing(false)}
          buttons={[
            {
              text: 'Annuleren',
              onPress: () => setEditing(false),
            },
            {
              text: 'OK',
              onPress: () => {
                const {isValid, errors} = onValidate?.(changes) ?? {
                  isValid: true,
                  errors: {},
                };

                if (!isValid) {
                  setErrors(errors);
                  return;
                } else {
                  setErrors(null);
                }
                if (onChange) {
                  onChange({...changes});
                }

                setEditing(false);
              },
            },
          ]}
          options={{noPaddingContent: true}}>
          {editDialog({changes, setChanges, errors})}
        </Dialog>
      )}
    </>
  );
};

const StageButton = ({stage, disabled, onPress}) => {
  let text;
  let icon;
  switch (stage) {
    case 'checkin':
      text = 'Afmelden';
      icon = <AssignmentTurnedInIcon fill="#FFFFFF" height={18} width={18} />;
      break;
    case 'checkout':
      text = 'Uitgeven';
      icon = <AssignmentReturnIcon fill="#FFFFFF" height={18} width={18} />;
      break;
    default:
      text = 'Reserveren';
      icon = <InsertInvitationIcon fill="#FFFFFF" height={18} width={18} />;
  }

  return (
    <PrimaryButton
      style={[sy.uppercase]}
      disabled={disabled}
      onPress={onPress}
      icon={icon}>
      <>{text}</>
    </PrimaryButton>
  );
};

const Footer = ({
  disabled,
  hasSLA,
  car,
  kilometrage,
  invoiceNumber,
  status,
  rob,
  fleet_request,
  onRequest,
  onFinish,
  onInvoice,
}) => {
  const [showRobErrorsDialog, setShowRobErrorsDialog] = useState(false);

  if (
    isArchived(fleet_request?.status ?? rob?.rob_status) ||
    status === STATUS_CODES.Invoiced
  ) {
    return <></>;
  }

  let stage = ROB_REPLACEMENT_STAGE.reservation;
  if (hasSLA) {
    if (car?.license && _.isNumber(kilometrage)) {
      stage = ROB_REPLACEMENT_STAGE.checkin;
    } else if (car?.license) {
      stage = ROB_REPLACEMENT_STAGE.checkout;
    }
  }

  return (
    <>
      <View style={[sy.footerView, sy['justify-end']]}>
        <View style={[sy['flex-row'], sy['items-center']]}>
          {rob?.errors?.length > 0 && (
            <Pressable
              style={[sy['pr-4']]}
              onPress={() => setShowRobErrorsDialog(true)}>
              <ErrorIcon fill="#972727" />
            </Pressable>
          )}
          {canInvoice(fleet_request?.status ?? rob?.rob_status) ? (
            [STATUS_CODES.Done, STATUS_CODES.DoneNoInvoice].includes(status) ===
            false ? (
              <>
                <PrimaryButton
                  style={[sy.uppercase]}
                  disabled={isNotReadyForInvoicing(
                    (fleet_request ?? rob)?.invoicing?.status,
                  )}
                  onPress={onFinish}
                  icon={() => (
                    <ServiceRequestStatusIcon
                      status={STATUS_CODES.Done}
                      fill="#FFFFFF"
                      iconProps={{width: 18, height: 18}}
                    />
                  )}>
                  Gereed melden
                </PrimaryButton>
              </>
            ) : (
              <PrimaryButton
                disabled={
                  !invoiceNumber ||
                  (fleet_request ?? rob)?.invoicing?.status !==
                    'ReadyForInvoicing'
                }
                style={[sy.uppercase]}
                onPress={onInvoice}
                icon={() => (
                  <ServiceRequestStatusIcon
                    status={STATUS_CODES.Invoiced}
                    fill="#FFFFFF"
                    iconProps={{width: 18, height: 18}}
                  />
                )}>
                Factureren
              </PrimaryButton>
            )
          ) : (
            <StageButton
              stage={stage}
              disabled={disabled}
              onPress={onRequest}
            />
          )}
        </View>
      </View>
      <Dialog
        visible={showRobErrorsDialog}
        title="Fout werkopdracht"
        buttons={[
          {
            text: 'OK',
            onPress: () => setShowRobErrorsDialog(false),
          },
        ]}>
        <ScrollView contentContainerStyle={{maxHeight: 400}}>
          {rob?.errors.map((item, index) => (
            <View style={[sy['flex-row'], sy['py-4']]} key={index}>
              <View style={[{flexShrink: 0}, sy['pr-4']]}>
                <ErrorIcon height="24px" width="24px" fill="#972727" />
              </View>
              <View style={[sy['flex-1']]}>
                <Text>{ROB_ERROR_TRANSLATIONS[item.code] ?? item.error}</Text>
                <Text style={[sy.small, sy['text-lightgray']]}>
                  {item.code}
                </Text>
              </View>
            </View>
          ))}
        </ScrollView>
      </Dialog>
    </>
  );
};

const ReplacementCar = ({
  dimensions,
  navigation,
  service_request,
  replacement,
  rob,
  fleet_request,
  onRefresh,
}) => {
  const [robContract, setRobContract] = useState(null);
  const [rental_classes, setRentalClasses] = useState(null);
  const [reason, setReason] = useState(
    replacement?.reason ?? REPLACEMENT_REASON.REPAIRS,
  );
  const [start_date, setStartDate] = useState(
    replacement?.datetime || service_request?.date,
  );
  const [start_time, setStartTime] = useState(
    replacement?.datetime || service_request?.datetime,
  );
  const [durationInDays, setDurationInDays] = useState(
    replacement?.duration_in_days || 1,
  );
  const [location, setLocation] = useState(replacement?.location ?? null);

  const [address, setAddress] = useState();
  const [car, _setCar] = useState(
    replacement?.car?.license ? replacement?.car : null,
  );
  const setCar = (value) => {
    _setCar((prevState) => {
      if (
        value &&
        (prevState?.is_estate !== value?.is_estate ||
          prevState?.gearbox !== value?.gearbox)
      ) {
        setRentalCharge(null);
        setKmSurcharge(null);
        setFreeKilometrage(null);
      }

      return value;
    });
  };

  const [fuel, _setFuel] = useState(replacement?.car?.fuel);
  const setFuel = (value) => {
    _setFuel((prevState) => {
      if (value && prevState !== value) {
        setRentalCharge(null);
        setKmSurcharge(null);
        setFreeKilometrage(null);
      }

      return value ?? prevState;
    });
  };

  const [rental_class, _setRentalClass] = useState(replacement?.rental_class);
  const setRentalClass = (value) => {
    _setRentalClass((prevState) => {
      if (value && prevState !== value) {
        setRentalCharge(null);
        setKmSurcharge(null);
        setFreeKilometrage(null);
      }

      return value ?? prevState;
    });
  };
  const [sla, setSla] = useState(null);
  const [rentalCharge, setRentalCharge] = useState(replacement?.charge_per_day);
  const [kmSurcharge, setKmSurcharge] = useState(replacement?.surcharge);

  const [freeKilometrage, setFreeKilometrage] = useState(
    replacement?.free_kilometrage,
  );
  const [daysCharged, setDaysCharged] = useState(
    replacement?.days_charged ?? 1,
  );
  const [kilometrage, setKilometrage] = useState(replacement?.kilometrage);
  const [extensionInDays, setExtensionInDays] = useState(
    replacement?.extension_in_days,
  );
  const [extensionComment, setExtensionComment] = useState(
    replacement?.extension_comment,
  );
  const [costs, setCosts] = useState(replacement?.costs ?? {});

  const [invoice, setInvoice] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const [edit_invoiceNumber, setEditInvoiceNumber] = useState(null);

  const [showEditCar, setShowEditCar] = useState(false);
  const [showEditRentalClass, setShowEditRentalClass] = useState(false);
  const [showNoInvoiceDialog, setShowNoInvoiceDialog] = useState(false);
  const [showNoInvoiceInfoDialog, setShowNoInvoiceInfoDialog] = useState(false);
  const [showInvoicePdfUploadDialog, setShowInvoicePdfUploadDialog] =
    useState(false);

  const [readLastMessage, setReadLastMessage] = useState(true);
  const [showSaveMessageDialog, setShowSaveMessageDialog] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(null);

  const [editDateTime, setEditDateTime] = useState(null);
  const [editDays, setEditDays] = useState(null);

  const dispatch = useDispatch();
  const forceRefresh = useSelector((state) => state.serviceRequestUpdate);
  const lastFetch = useSelector((state) => state.serviceRequestLastFetch);

  const isFleet = !!service_request.fleet_contract_id;

  const getChanges = useCallback(() => {
    const datetime = new Date(start_date);
    const time = new Date(start_time);
    datetime.setHours(time.getHours());
    datetime.setMinutes(time.getMinutes());

    const changes = {
      sla: replacement.sla,
      datetime: datetime.toISOString(),
      duration_in_days: durationInDays,
      reason,
      location,
      car: {
        fuel,
        ...(car ?? {}),
      },
      rental_class,
      days_charged: daysCharged,
      extension_in_days: extensionInDays,
      extension_comment: extensionComment,
      charge_per_day: rentalCharge,
      surcharge: kmSurcharge,
      free_kilometrage: Math.round(freeKilometrage),
      kilometrage,
      costs,
    };

    return changes;
  }, [
    replacement,
    start_date,
    start_time,
    durationInDays,
    reason,
    location,
    fuel,
    car,
    rental_class,
    daysCharged,
    extensionInDays,
    extensionComment,
    rentalCharge,
    kmSurcharge,
    freeKilometrage,
    kilometrage,
    costs,
  ]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      const changes = pickDeep({}, getChanges());
      const existing = pickDeep({}, _.pick(replacement, Object.keys(changes)));

      console.log('changes', changes);
      console.log('existing', existing);

      const isDirty = !_.isEqual(changes, existing);

      console.log('isDirty', isDirty);

      if (isDirty) {
        serviceRequestApi.patch(replacement.request_id, {
          replacement: changes,
        });
      }
    });

    return unsubscribe;
  }, [navigation, replacement, getChanges]);

  useEffect(() => {
    const fetch = async () => {
      const last_fetch_date = moment(
        lastFetch[replacement.request_id] ?? new Date(0),
      );
      const update_date = moment(
        forceRefresh[replacement.request_id]?.timestamp ?? new Date(0),
      );
      if (update_date.isAfter(last_fetch_date, 'seconds')) {
        dispatch(serviceRequestLastFetch(replacement.request_id));

        console.log(
          'Refreshing service replacement request',
          replacement.request_id,
          replacement.status,
          last_fetch_date,
          update_date,
        );

        if (
          isLessorStatus(
            forceRefresh[replacement.request_id]?.status ??
              forceRefresh[replacement.request_id]?.rob_status,
          ) &&
          !isLessorStatus(replacement.status)
        ) {
          // Do a reload to make sure we have loaded the latest data
          window.location.reload();
        } else {
          await onRefresh();
        }
      }
    };

    if (replacement) {
      fetch();
    }
  }, [replacement, forceRefresh, lastFetch, dispatch, onRefresh]);

  useEffect(() => {
    const fetch = async () => {
      const {result: rental_classes} = await robApi.rental_classes();
      setRentalClasses(rental_classes);

      let contract;
      if (service_request.fleet_contract_id) {
        const {result} = await fleetApi.contract(
          service_request.fleet_contract_id,
        );
        contract = result.contract;
      } else {
        ({result: contract} = await robApi.contract(
          service_request.license,
          service_request.id,
        ));
      }

      setRobContract(contract);

      _setFuel(
        (prevState) =>
          prevState ??
          contract?.leaseContract?.rentalVehicle.fuelType.toLowerCase(),
      );

      _setRentalClass(
        (prevState) =>
          prevState ??
          contract?.leaseContract?.rentalVehicle.rentalClass ??
          rental_classes[0].code,
      );

      if (
        replacement &&
        (fleet_request || rob) &&
        (replacement.status === STATUS_CODES.Done ||
          replacement.status === STATUS_CODES.Invoiced ||
          replacement.status === STATUS_CODES.Archived ||
          (fleet_request ?? rob).invoicing?.status === 'ReadyForInvoicing' ||
          (fleet_request ?? rob).invoicing?.status === 'Invoiced')
      ) {
        const {result: invoice} = await serviceRequestApi.invoice(
          replacement.request_id,
        );

        setInvoice(invoice);
      }
    };

    fetch();
  }, [service_request, replacement, rob]);

  useEffect(() => {
    if (fuel && rental_class) {
      const fetch = async () => {
        const {result: sla} = await serviceRequestApi.sla_replacement_vehicle(
          replacement.request_id,
          fuel,
          rental_class,
          car?.is_estate,
          car?.gearbox,
        );

        setSla(sla);

        setRentalCharge(
          (prevState) =>
            prevState ?? sla[ROB_REPLACEMENT_RENTAL_CHARGE_CODE]?.amount,
        );
        setKmSurcharge(
          (prevState) =>
            prevState ?? sla[ROB_REPLACEMENT_KM_SURCHARGE_CODE]?.amount,
        );
        setFreeKilometrage(
          (prevState) => prevState ?? sla[ROB_REPLACEMENT_FREE_KM_CODE]?.amount,
        );
      };

      fetch();
    }
  }, [service_request, replacement, fuel, rental_class, car]);

  const {zipCode, houseNumber} = location?.address ?? {};
  useEffect(() => {
    if (zipCode && houseNumber) {
      const fetch = async () => {
        const {address} = await addressApi.fetch(zipCode, houseNumber);

        setAddress(address);
      };

      fetch();
    }
  }, [zipCode, houseNumber]);

  useEffect(() => {
    if (replacement.last_message) {
      setReadLastMessage(
        !replacement.last_message?.user_id
          ? replacement.last_message?.is_read ?? true
          : true,
      );
    }
  }, [replacement]);

  useEffect(() => {
    const fetch = async () => {
      const changes = pickDeep({}, getChanges());
      const existing = pickDeep({}, _.pick(replacement, Object.keys(changes)));

      console.log('changes', changes);
      console.log('existing', existing);

      const isDirty = !_.isEqual(changes, existing);

      console.log('isDirty', isDirty);

      if (isDirty) {
        console.log('Reactivating replacement vehicle task');

        await serviceRequestApi.patch(replacement.request_id, {
          replacement: changes,
        });

        await serviceRequestApi.change_status(
          replacement.request_id,
          STATUS_CODES.Modified,
        );

        if (rob) {
          await robApi.activate_task(
            replacement.request_id,
            rob.rob_id,
            rob.task_id,
          );
        } else if (fleet_request) {
          await onRefresh();
        }
      }
    };

    if ((fleet_request?.status ?? rob?.rob_status) === ROB_STATUSES.Approved) {
      fetch();
    }
  }, [
    getChanges,
    replacement,
    rob,
    fleet_request,
    start_date,
    start_time,
    durationInDays,
    reason,
    location,
    fuel,
    car,
    rental_class,
    daysCharged,
    extensionInDays,
    extensionComment,
    rentalCharge,
    kmSurcharge,
    freeKilometrage,
    kilometrage,
    costs,
  ]);

  const onRequest = async () => {
    if (pendingMessage?.length > 0) {
      setShowSaveMessageDialog(true);
      return true;
    }

    const changes = getChanges();
    if (isFleet) {
      await fleetApi.request_replacement_vehicle(service_request.id, changes);
    } else {
      await robApi.request_replacement_vehicle(service_request.id, changes);
    }

    onRefresh();
  };

  const onFinish = async () => {
    await serviceRequestApi.change_status(
      replacement.request_id,
      STATUS_CODES.Done,
    );

    onRefresh();
  };

  const onUpdateRequest = async (invoice_number) => {
    const result = await serviceRequestApi.patch(replacement.request_id, {
      datetime: replacement.datetime,
      invoice_number,
    });

    if (result?.success) {
      onRefresh();
    }

    return result?.success;
  };

  const onInvoice = async () => {
    if (invoice?.pdf_upload && !invoice?.bahnkick_can_invoice) {
      setShowInvoicePdfUploadDialog(true);
    } else {
      await InvoiceServiceRequest();
    }
  };

  const InvoiceServiceRequest = async (pdf) => {
    dispatch(setAppIsLoading());
    try {
      let result;
      if (isFleet) {
        result = await fleetApi.invoice(replacement.request_id, pdf);
      } else {
        result = await robApi.invoice(
          replacement.request_id,
          replacement.invoice_number,
        );
      }

      if (result?.success) {
        onRefresh();
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  const onChangeInvoiceStatus = async () => {
    dispatch(setAppIsLoading());
    try {
      if (replacement.invoice_number) {
        // Clear the entered invoice number
        await serviceRequestApi.patch(replacement.request_id, {
          datetime: replacement.datetime,
          invoice_number: null,
        });
      }

      const {success} = await robApi.change_invoice_status(
        replacement.request_id,
        invoiceStatus,
      );

      if (success) {
        setShowNoInvoiceDialog(false);
        setShowNoInvoiceInfoDialog(true);
        onRefresh();
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  const onSendChatMessage = async () => {
    const {success} = await serviceRequestApi.send_message(
      replacement.request_id,
      pendingMessage,
    );
    if (success) {
      setPendingMessage(null);
      onRefresh();
    }
  };

  const is_disabled = () => {
    if (isLessorStatus(fleet_request?.status ?? rob?.rob_status)) {
      return true;
    }

    if (
      replacement &&
      (fleet_request || rob) &&
      (replacement.status === STATUS_CODES.Invoiced ||
        replacement.status === STATUS_CODES.Archived ||
        (fleet_request ?? rob).invoicing?.status === 'Invoiced' ||
        isNotInvoiced((fleet_request ?? rob).invoicing?.status))
    ) {
      return true;
    }

    return false;
  };

  let stage = ROB_REPLACEMENT_STAGE.reservation;
  if (
    _.isNumber(rentalCharge) &&
    _.isNumber(kmSurcharge) &&
    _.isNumber(freeKilometrage)
  ) {
    if (car?.license && _.isNumber(kilometrage)) {
      stage = ROB_REPLACEMENT_STAGE.checkin;
    } else if (car?.license) {
      stage = ROB_REPLACEMENT_STAGE.checkout;
    }
  }

  const contract_rental_class =
    robContract?.leaseContract?.rentalVehicle.rentalClass;

  const total_duration_in_days = durationInDays + (extensionInDays ?? 0);

  const surcharge = _.isNumber(kilometrage)
    ? kmSurcharge *
      Math.max(kilometrage - freeKilometrage * total_duration_in_days, 0)
    : null;

  let total_amount = 0;
  if (!isRobTaskCancelled((fleet_request ?? rob)?.task_status)) {
    if (_.isNumber(rentalCharge)) {
      total_amount += daysCharged * rentalCharge;
    }

    if (surcharge) {
      total_amount += surcharge;
    }

    if (costs) {
      total_amount += Object.keys(costs)
        .filter((key) => !key.endsWith('_comment'))
        .filter((key) => _.isNumber(costs[key]))
        .reduce((acc, key) => acc + costs[key], 0);
    }
  }

  const extension_comment_missing = extensionInDays > 0 && !extensionComment;

  return (
    <>
      <ScrollView>
        <Grouping
          icon={<EventIcon fill="#4A4A49" />}
          header={
            <>
              <Text style={[sy.mediumPlus, sy.capitalize]}>
                {moment(start_date, 'YYYY-MM-DD').format('dddd')}
              </Text>
              &nbsp;{moment(start_date, 'YYYY-MM-DD').format('D MMMM')}
              &nbsp;&middot;&nbsp;{total_duration_in_days}
              &nbsp;dag
              {total_duration_in_days === 1 ? '' : 'en'}
            </>
          }
          options={{topMost: true}}>
          <GroupingRow title="Vestiging">
            <Text>{service_request.dealer_city}</Text>
          </GroupingRow>
          <GroupingRow title="Startdatum" disabled={is_disabled('')}>
            <Text>
              {moment(start_date, 'YYYY-MM-DD').format('dddd D MMMM YYYY')}
            </Text>
            <GroupingRowEdit
              title="Startdatum wijzigen"
              dismissable
              cancelButton
              disableOKButton={!editDateTime}
              onClose={() => setEditDateTime(null)}
              onOK={() => setStartDate(editDateTime)}>
              <Calendar
                months={[
                  /* Three months in the past and six in the future */
                  -3, -2, -1, 0, 1, 2, 3, 4, 5, 6,
                ]}
                initialDate={start_date}
                date={editDateTime ?? start_date}
                onPress={setEditDateTime}
              />
            </GroupingRowEdit>
          </GroupingRow>
          <GroupingRow title="Starttijd" disabled={is_disabled('')}>
            <Text>{moment.utc(start_time).format('H:mm')}</Text>
            <GroupingRowEdit
              title="Starttijd wijzigen"
              dismissable
              cancelButton
              disableOKButton={!editDateTime}
              onClose={() => setEditDateTime(null)}
              onOK={() => setStartTime(editDateTime)}>
              <ScrollView style={[sy['pl-4'], {maxHeight: 400}]}>
                <TimeSlots
                  begin_checkin={moment().startOf('day').hours(6)}
                  end_checkin={moment().startOf('day').hours(19)}
                  datetime={editDateTime ?? start_time}
                  onPress={setEditDateTime}
                />
              </ScrollView>
            </GroupingRowEdit>
          </GroupingRow>
          <GroupingRow title="Einddatum" disabled={is_disabled('')}>
            <Text>
              {moment(start_date, 'YYYY-MM-DD')
                .add(total_duration_in_days - 1, 'days')
                .format('dddd D MMMM YYYY')}
            </Text>
            <GroupingRowEdit
              title="Einddatum wijzigen"
              dismissable
              cancelButton
              disableOKButton={!editDays}
              onClose={() => setEditDays(null)}
              onOK={() => {
                setDaysCharged(editDays);
                if (
                  [ROB_TASK_STATUS.Approved, ROB_TASK_STATUS.Changed].includes(
                    (fleet_request ?? rob)?.stages?.[
                      ROB_REPLACEMENT_STAGE.checkout
                    ]?.status,
                  )
                ) {
                  setExtensionInDays(Math.max(editDays - durationInDays, 0));
                  if (editDays < durationInDays) {
                    setDurationInDays(editDays);
                  }
                } else {
                  setDurationInDays(editDays);
                }
              }}>
              <Calendar
                months={[
                  /* Six months in the future */
                  -1, 0, 1, 2, 3, 4, 5, 6,
                ]}
                initialDate={moment(start_date, 'YYYY-MM-DD').add(
                  total_duration_in_days - 1,
                  'days',
                )}
                date={moment(start_date, 'YYYY-MM-DD').add(
                  (editDays ?? total_duration_in_days) - 1,
                  'days',
                )}
                minDate={start_date}
                onClose={() => setEditDays(null)}
                onPress={(date) => {
                  const days = moment(
                    moment(date).add(1, 'days').startOf('day'),
                  ).diff(moment(start_date).startOf('day'), 'days');

                  setEditDays(days);
                }}
              />
            </GroupingRowEdit>
          </GroupingRow>
          <GroupingRow title="Reden inzet" disabled={is_disabled('')}>
            <Text>
              {isFleet
                ? FLEET_REPLACEMENT_REASON_TITLE[reason]
                : REPLACEMENT_REASON_TITLE[reason]}
            </Text>
            <GroupingRowEdit
              title="Reden inzet"
              initialValue={reason}
              dismissable
              cancelButton
              onCancel={({initialValue}) => setReason(initialValue)}>
              <View style={sy['p-4']}>
                <Select
                  label="Reden"
                  value={reason}
                  options={Object.values(
                    isFleet ? FLEET_REPLACEMENT_REASON : REPLACEMENT_REASON,
                  ).map((item) => ({
                    text: isFleet
                      ? FLEET_REPLACEMENT_REASON_TITLE[item]
                      : REPLACEMENT_REASON_TITLE[item],
                    value: item,
                  }))}
                  onChange={(option) => setReason(option.value)}
                />
              </View>
            </GroupingRowEdit>
          </GroupingRow>
          <GroupingRow title="Locatie" disabled={is_disabled('')}>
            <Text>{location ? 'Andere locatie' : 'Eigen locatie'}</Text>
            <GroupingRowEdit
              title="Locatie"
              initialValue={location}
              dismissable
              cancelButton
              onCancel={({initialValue}) => setLocation(initialValue)}>
              <Pressable
                disabled={is_disabled('')}
                style={[sy['p-4'], sy['flex-row'], sy['items-center']]}
                onPress={() => setLocation(null)}>
                <RadioButton disabled={is_disabled('')} checked={!location} />
                <Text style={[sy['ml-8']]}>Eigen locatie</Text>
              </Pressable>
              <Divider style={[sy['bg-gray95'], sy['mx-4']]} />
              <Pressable
                disabled={is_disabled('')}
                style={[sy['p-4'], sy['flex-row'], sy['items-center']]}
                onPress={() => setLocation({})}>
                <RadioButton disabled={is_disabled('')} checked={location} />
                <Text style={[sy['ml-8']]}>Andere locatie</Text>
              </Pressable>
            </GroupingRowEdit>
          </GroupingRow>
          {!!location && (
            <>
              <View
                style={[
                  sy['p-4'],
                  sy['pb-0'],
                  sy['flex-row'],
                  sy['items-center'],
                  sy['gap-4'],
                ]}>
                <AccountCircleIcon fill="4a4a49" />
                <FormTextInput
                  disabled={is_disabled('')}
                  style={[sy['flex-1']]}
                  label="Bedrijfsnaam en/of contactpersoon"
                  value={location?.name}
                  onChangeText={(name) =>
                    setLocation((prevState) => ({...(prevState ?? {}), name}))
                  }
                />
              </View>
              <View
                style={[
                  sy['p-4'],
                  sy['pb-0'],
                  sy['flex-row'],
                  sy['items-center'],
                  sy['gap-4'],
                ]}>
                <PhoneIcon fill="4a4a49" />
                <FormTextInput
                  disabled={is_disabled('')}
                  style={[sy['flex-1']]}
                  label="Telefoonnummer"
                  value={location?.phone}
                  onChangeText={(phone) =>
                    setLocation((prevState) => ({
                      ...(prevState ?? {}),
                      phone,
                    }))
                  }
                />
              </View>
              <View
                style={[
                  sy['p-4'],
                  sy['pb-0'],
                  sy['flex-row'],
                  sy['items-center'],
                  sy['gap-4'],
                ]}>
                <HomeFilledIcon fill="4a4a49" />
                <View style={[sy['flex-row'], sy['flex-1'], sy['gap-4']]}>
                  <FormTextInput
                    disabled={is_disabled('')}
                    style={[sy['flex-1']]}
                    label="Postcode"
                    value={location?.address?.zipCode}
                    onChangeText={(zipCode) =>
                      setLocation((prevState) => ({
                        ...(prevState ?? {}),
                        address: {
                          ...(prevState?.address ?? {}),
                          zipCode,
                        },
                      }))
                    }
                  />
                  <FormTextInput
                    disabled={is_disabled('')}
                    style={[sy['flex-1']]}
                    label="Huisnummer"
                    value={location?.address?.houseNumber}
                    onChangeText={(houseNumber) =>
                      setLocation((prevState) => ({
                        ...(prevState ?? {}),
                        address: {
                          ...(prevState?.address ?? {}),
                          houseNumber,
                        },
                      }))
                    }
                  />
                </View>
              </View>
              <View
                style={[
                  sy['p-2'],
                  sy['pl-21'],
                  sy['flex-row'],
                  sy['items-center'],
                ]}>
                <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                  {address}
                </Text>
              </View>
            </>
          )}
        </Grouping>

        <Grouping
          icon={
            <BusinessIcon
              fill={robContract?.isActive === false ? '#972727' : '#4A4A49'}
            />
          }
          header={service_request?.lessor}>
          {service_request.fleet_contract_id ? (
            <></>
          ) : (
            <GroupingRow title="Telefoonnummer">
              <LessorPhone lessor={robContract?.lessor} />
            </GroupingRow>
          )}
          <GroupingRow title="Leaseauto">
            <Text>{service_request.car_name}</Text>
          </GroupingRow>
          <GroupingRow title="Huurklasse contract">
            {contract_rental_class ? (
              <RentalClassText
                rental_class={contract_rental_class}
                rental_classes={rental_classes}
              />
            ) : (
              <Text>Niet opgegeven</Text>
            )}
          </GroupingRow>
          <GroupingRow title="Brengkosten">
            <Text>
              {robContract?.permissions.replacement_vehicle.costs.delivery ? (
                <>Toegestaan</>
              ) : (
                <>Niet toegestaan</>
              )}
            </Text>
          </GroupingRow>
          <GroupingRow title="Haalkosten">
            <Text>
              {robContract?.permissions.replacement_vehicle.costs.pickup ? (
                <>Toegestaan</>
              ) : (
                <>Niet toegestaan</>
              )}
            </Text>
          </GroupingRow>
          <GroupingRow title="Aftankkosten">
            <Text>
              {robContract?.permissions.replacement_vehicle.costs
                .fuel_service ? (
                <>Toegestaan</>
              ) : (
                <>Niet toegestaan</>
              )}
            </Text>
          </GroupingRow>

          {service_request.fleet_contract_id ? (
            <></>
          ) : (
            <GroupingRow>
              <View style={[sy['flex-row'], sy['justify-between']]}>
                {robContract?.isActive ? (
                  <Pressable
                    style={[sy['flex-row']]}
                    onPress={async () => {
                      const {result: rob_contract} =
                        await robApi.update_contract(
                          service_request.license,
                          service_request.id,
                        );

                      setRobContract(rob_contract);
                    }}>
                    <View style={[sy['pr-8']]}>
                      <RefreshIcon fill="#231fda" />
                    </View>
                    <View>
                      <Link>Gegevens vernieuwen</Link>
                      <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                        Laatst bijgewerkt&nbsp;
                        {moment(robContract?.timestamp).format(
                          'D MMMM YYYY H:mm',
                        )}
                      </Text>
                    </View>
                  </Pressable>
                ) : (
                  <View style={sy['flex-row']}>
                    <View style={[sy['pr-8']]}>
                      <WarningLargeIcon fill="#972727" />
                    </View>
                    <View>
                      <Text>Contract inactief</Text>
                      <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                        Laatst bijgewerkt&nbsp;
                        {moment(robContract?.timestamp).format(
                          'D MMMM YYYY H:mm',
                        )}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </GroupingRow>
          )}
        </Grouping>

        <Grouping
          icon={<CarRentalIcon fill="#4A4A49" />}
          header={
            car ? (
              <>
                {car.license}&nbsp;&middot;&nbsp;{car.name}
              </>
            ) : (
              'Auto inzet'
            )
          }>
          <GroupingRow title="Kenteken">
            <Link
              disabled={is_disabled('')}
              onPress={() => setShowEditCar(true)}>
              {car?.license ?? 'Keuze maken'}
            </Link>
          </GroupingRow>
          {car?.license && (
            <>
              <GroupingRow title="Auto">
                <Text>{car?.name}</Text>
              </GroupingRow>
              <GroupingRow title="Huurklasse inzet" disabled={is_disabled('')}>
                <Text>
                  <RentalClassText
                    rental_class={car?.rental_class}
                    rental_classes={rental_classes}
                    contract_rental_class={contract_rental_class}
                  />
                </Text>
              </GroupingRow>
            </>
          )}
        </Grouping>

        <Grouping
          icon={<PersonIcon fill="#4A4A49" />}
          header={
            <>
              {rental_class}
              {sla && _.isNumber(rentalCharge) && _.isNumber(kmSurcharge) && (
                <>
                  &nbsp;&middot;&nbsp;
                  <>{Format.price(rentalCharge)}</>
                  &nbsp;(
                  <>{Format.price(kmSurcharge)}</>)
                </>
              )}
            </>
          }>
          <GroupingRow title="Huurklasse" disabled={is_disabled()}>
            <Link
              disabled={is_disabled()}
              onPress={() => setShowEditRentalClass(true)}>
              <RentalClassText
                rental_class={rental_class}
                rental_classes={rental_classes}
                contract_rental_class={contract_rental_class}
                rental_car_class={car?.rental_class}
              />
            </Link>
          </GroupingRow>
          <GroupingRow title="Dagtarief" disabled={is_disabled()}>
            <Text>
              {_.isNumber(rentalCharge) ? (
                Format.price(rentalCharge)
              ) : (
                <>Opgeven</>
              )}
            </Text>
            <GroupingRowEdit
              title="Dagtarief"
              initialValue={rentalCharge}
              dismissable
              cancelButton
              onCancel={({initialValue}) => setRentalCharge(initialValue)}>
              <View style={sy['p-4']}>
                <FormNumberInput
                  value={rentalCharge}
                  digits={2}
                  onChangeNumber={setRentalCharge}
                />
                <Text
                  style={[
                    sy.smallRegular,
                    {paddingLeft: 12},
                    sy['text-lightgray'],
                  ]}>
                  SLA:&nbsp;
                  {sla?.[ROB_REPLACEMENT_RENTAL_CHARGE_CODE]?.value ? (
                    <>
                      {Format.price(
                        sla?.[ROB_REPLACEMENT_RENTAL_CHARGE_CODE]?.value,
                      )}
                    </>
                  ) : (
                    <>Geen</>
                  )}
                </Text>

                {sla?.[ROB_REPLACEMENT_RENTAL_CHARGE_CODE]?.surcharges.map(
                  (surcharge) => (
                    <Text
                      key={surcharge.item}
                      style={[
                        sy.smallRegular,
                        {paddingLeft: 12},
                        sy['text-lightgray'],
                      ]}>
                      {surcharge.item}:&nbsp;{Format.price(surcharge.amount)}
                    </Text>
                  ),
                )}
              </View>
            </GroupingRowEdit>
          </GroupingRow>
          <GroupingRow title="Meerkilometertarief" disabled={is_disabled('')}>
            <Text>
              {_.isNumber(kmSurcharge) ? (
                Format.price(kmSurcharge)
              ) : (
                <>Opgeven</>
              )}
            </Text>
            <GroupingRowEdit
              title="Meerkilometertarief"
              initialValue={kmSurcharge}
              dismissable
              cancelButton
              onCancel={({initialValue}) => setKmSurcharge(initialValue)}>
              <View style={sy['p-4']}>
                <FormNumberInput
                  value={kmSurcharge}
                  digits={2}
                  onChangeNumber={setKmSurcharge}
                />
                <Text
                  style={[
                    sy.smallRegular,
                    {paddingLeft: 12},
                    sy['text-lightgray'],
                  ]}>
                  SLA:&nbsp;
                  {sla?.[ROB_REPLACEMENT_KM_SURCHARGE_CODE]?.amount ? (
                    Format.price(
                      sla?.[ROB_REPLACEMENT_KM_SURCHARGE_CODE]?.amount,
                    )
                  ) : (
                    <>Geen</>
                  )}
                </Text>
              </View>
            </GroupingRowEdit>
          </GroupingRow>
          <GroupingRow
            title="Vrije kilometers per dag"
            disabled={is_disabled('')}>
            <Text>
              {_.isNumber(freeKilometrage) ? (
                Format.number(freeKilometrage)
              ) : (
                <>Opgeven</>
              )}
            </Text>
            <GroupingRowEdit
              title="Vrije kilometers per dag"
              initialValue={freeKilometrage}
              dismissable
              cancelButton
              onCancel={({initialValue}) => setFreeKilometrage(initialValue)}>
              <View style={sy['p-4']}>
                <FormNumberInput
                  value={freeKilometrage}
                  digits={0}
                  onChangeNumber={setFreeKilometrage}
                />
                <Text
                  style={[
                    sy.smallRegular,
                    {paddingLeft: 12},
                    sy['text-lightgray'],
                  ]}>
                  SLA:&nbsp;
                  {sla?.[ROB_REPLACEMENT_FREE_KM_CODE]?.amount ? (
                    <>
                      {Format.number(
                        sla?.[ROB_REPLACEMENT_FREE_KM_CODE]?.amount,
                        0,
                      )}
                      &nbsp;km&nbsp;vrij
                    </>
                  ) : (
                    <>Geen</>
                  )}
                </Text>
              </View>
            </GroupingRowEdit>
          </GroupingRow>
        </Grouping>

        <Divider />
        <View style={[sy['p-4'], sy['flex-row']]}>
          <View style={[sy['pr-8']]}>
            <AssignmentIcon fill="#4A4A49" />
          </View>
          <View style={[sy['flex-row'], sy['justify-between'], sy['flex-1']]}>
            <Text style={sy.mediumPlus}>
              Opdracht
              {isNotInvoiced((fleet_request ?? rob)?.invoicing?.status) ? (
                <>
                  &nbsp;&middot;&nbsp;
                  {STATUS_DESCRIPTIONS[STATUS_CODES.NoInvoice]}
                </>
              ) : replacement?.status ? (
                <>
                  &nbsp;&middot;&nbsp;
                  {isLessorStatus(replacement?.status)
                    ? ROB_REPLACEMENT_STAGE_TITLE[stage]
                    : STATUS_DESCRIPTIONS[replacement?.status]}
                </>
              ) : (
                <></>
              )}
            </Text>
            <Text style={sy.mediumPlus}>
              {stage === ROB_REPLACEMENT_STAGE.reservation ? (
                Format.price(0)
              ) : _.isNumber(rentalCharge) && _.isNumber(kmSurcharge) ? (
                Format.price(total_amount)
              ) : (
                <></>
              )}
            </Text>
          </View>
        </View>
        <Divider style={sy['bg-gray95']} />
        <LineItem
          topMost={true}
          icon={
            <DateRangeIcon
              fill={extension_comment_missing ? '#FF6C00' : '#4A4A49'}
            />
          }
          title="Verhuur"
          disabled={is_disabled('')}
          cancelled={isRobTaskCancelled((fleet_request ?? rob)?.task_status)}
          price={
            !extension_comment_missing ? (
              stage !== ROB_REPLACEMENT_STAGE.reservation && daysCharged > 0 ? (
                daysCharged * (rentalCharge ?? 0)
              ) : (
                <></>
              )
            ) : null
          }
          subtitleColor={extension_comment_missing ? '#FF6C00' : null}
          subtitle={
            <>
              {daysCharged}&nbsp;dag{daysCharged === 1 ? '' : 'en'}
            </>
          }
          initialValues={{
            daysCharged,
            rentalCharge,
            durationInDays,
            extensionInDays,
            extensionComment,
          }}
          onValidate={({extensionInDays, extensionComment}) => {
            if (extensionInDays > 0 && !extensionComment) {
              return {
                isValid: false,
                errors: {
                  extensionComment: true,
                },
              };
            }
          }}
          onChange={({
            daysCharged,
            rentalCharge,
            durationInDays,
            extensionInDays,
            extensionComment,
          }) => {
            setDaysCharged(daysCharged);
            setRentalCharge(rentalCharge);
            setDurationInDays(durationInDays);
            setExtensionInDays(extensionInDays);
            setExtensionComment(extensionComment);
          }}
          editDialog={({changes, setChanges, errors}) => (
            <>
              <View style={[sy['p-4']]}>
                <FormNumberInput
                  label="Aantal dagen factureren"
                  value={changes.daysCharged}
                  digits={0}
                  onChangeNumber={(daysCharged) =>
                    setChanges((prevState) => {
                      let extensionInDays = prevState.extensionInDays;
                      let durationInDays = prevState.durationInDays;
                      if (
                        isRobTaskApproved(
                          (fleet_request ?? rob)?.stages[
                            ROB_REPLACEMENT_STAGE.checkout
                          ]?.status,
                        )
                      ) {
                        extensionInDays = Math.max(
                          extensionInDays ?? 0,
                          daysCharged - durationInDays,
                          0,
                        );
                      } else {
                        durationInDays = Math.max(durationInDays, daysCharged);
                      }

                      return {
                        ...prevState,
                        daysCharged,
                        extensionInDays,
                        durationInDays,
                      };
                    })
                  }
                />
                <Text
                  style={[
                    sy.smallRegular,
                    {paddingLeft: 12},
                    sy['text-lightgray'],
                  ]}>
                  {changes.durationInDays + (changes.extensionInDays ?? 0)}
                  &nbsp;dag
                  {changes.durationInDays + (changes.extensionInDays ?? 0) === 1
                    ? ''
                    : 'en'}
                  &nbsp;inzet
                  {changes.extensionInDays > 0 && <>&nbsp;(incl. verlenging)</>}
                </Text>
              </View>
              {[
                ROB_REPLACEMENT_STAGE.checkin,
                ROB_REPLACEMENT_STAGE.checkout,
              ].includes(stage) && (
                <>
                  <View style={[sy['p-4'], sy['pt-0']]}>
                    <FormNumberInput
                      label="Dagtarief (euro)"
                      digits={2}
                      value={changes.rentalCharge}
                      onChangeNumber={(number) =>
                        setChanges((prevState) => ({
                          ...prevState,
                          rentalCharge: number,
                        }))
                      }
                    />
                    <Text
                      style={[
                        sy.smallRegular,
                        {paddingLeft: 12},
                        sy['text-lightgray'],
                      ]}>
                      SLA:&nbsp;
                      {sla?.[ROB_REPLACEMENT_RENTAL_CHARGE_CODE]?.value ? (
                        Format.price(
                          sla?.[ROB_REPLACEMENT_RENTAL_CHARGE_CODE]?.value,
                        )
                      ) : (
                        <>Geen</>
                      )}
                    </Text>
                    {sla?.[ROB_REPLACEMENT_RENTAL_CHARGE_CODE]?.surcharges.map(
                      (surcharge) => (
                        <Text
                          key={surcharge.item}
                          style={[
                            sy.smallRegular,
                            {paddingLeft: 12},
                            sy['text-lightgray'],
                          ]}>
                          {surcharge.item}:&nbsp;
                          {Format.price(surcharge.amount)}
                        </Text>
                      ),
                    )}
                  </View>
                  <View style={[sy['p-4'], sy['pt-0']]}>
                    <FormNumberInput
                      label="Kosten (euro)"
                      value={changes.daysCharged * changes.rentalCharge}
                      onChangeNumber={(number) =>
                        setChanges((prevState) => ({
                          ...prevState,
                          rentalCharge: number / changes.daysCharged,
                        }))
                      }
                    />
                  </View>
                </>
              )}
              {changes.extensionInDays > 0 && (
                <View style={[sy['p-4'], sy['pt-0']]}>
                  <FormTextInput
                    label="Reden verlenging"
                    value={changes.extensionComment}
                    errorMessage="Dit veld is verplicht"
                    hasError={
                      (!changes.extensionCommentChanged &&
                        extension_comment_missing) ||
                      errors?.extensionComment
                    }
                    onChangeText={(text) =>
                      setChanges((prevState) => ({
                        ...prevState,
                        extensionComment: text,
                        extensionCommentChanged: true,
                      }))
                    }
                    inputProps={{
                      multiline: true,
                      numberOfLines: 8,
                    }}
                  />
                </View>
              )}
            </>
          )}
        />
        {[
          ROB_REPLACEMENT_STAGE.checkin,
          ROB_REPLACEMENT_STAGE.checkout,
        ].includes(stage) && (
          <LineItem
            icon={<AddRoadIcon fill="#4A4A49" />}
            title="Meerkilometers"
            disabled={is_disabled('')}
            cancelled={isRobTaskCancelled((fleet_request ?? rob)?.task_status)}
            price={surcharge}
            subtitle={
              <>
                {Format.number(
                  Math.max(
                    kilometrage - freeKilometrage * total_duration_in_days,
                    0,
                  ),
                )}
                &nbsp;km
              </>
            }
            initialValues={{freeKilometrage, kilometrage}}
            onChange={({freeKilometrage, kilometrage}) => {
              setFreeKilometrage(freeKilometrage);
              setKilometrage(kilometrage);
            }}
            editDialog={({changes, setChanges}) => (
              <>
                <View style={[sy['p-4']]}>
                  <FormNumberInput
                    label="Verreden kilometers"
                    value={changes.kilometrage}
                    digits={0}
                    onChangeNumber={(number) =>
                      setChanges((prevState) => ({
                        ...prevState,
                        kilometrage: number,
                      }))
                    }
                  />
                </View>
                <View style={[sy['p-4'], sy['pt-0']]}>
                  <FormNumberInput
                    label="Vrije kilometers"
                    value={changes.freeKilometrage * total_duration_in_days}
                    digits={0}
                    onChangeNumber={(number) =>
                      setChanges((prevState) => ({
                        ...prevState,
                        freeKilometrage: Math.round(
                          number / total_duration_in_days,
                        ),
                      }))
                    }
                  />
                  <Text
                    style={[
                      sy.smallRegular,
                      {paddingLeft: 12},
                      sy['text-lightgray'],
                    ]}>
                    SLA:&nbsp;
                    {sla?.[ROB_REPLACEMENT_FREE_KM_CODE]?.amount ? (
                      <>
                        {Format.number(
                          sla?.[ROB_REPLACEMENT_FREE_KM_CODE]?.amount *
                            total_duration_in_days,
                          0,
                        )}
                        &nbsp;km&nbsp;vrij
                      </>
                    ) : (
                      <>Geen</>
                    )}
                  </Text>
                </View>
                <View style={[sy['p-4'], sy['pt-0']]}>
                  <FormNumberInput
                    label="Meerkilometers"
                    value={
                      _.isNumber(changes.kilometrage)
                        ? Math.max(
                            changes.kilometrage -
                              changes.freeKilometrage * total_duration_in_days,
                            0,
                          )
                        : null
                    }
                    digits={0}
                    onChangeNumber={(number) =>
                      setChanges((prevState) => ({
                        ...prevState,
                        kilometrage: Math.max(
                          freeKilometrage * total_duration_in_days + number,
                          0,
                        ),
                      }))
                    }
                  />
                </View>
              </>
            )}
          />
        )}
        {location && (
          <LineItem
            icon={<SyncAltIcon fill="#4A4A49" />}
            title="Breng- en haalkosten"
            allowed={
              robContract?.permissions.replacement_vehicle.costs.delivery ||
              robContract?.permissions.replacement_vehicle.costs.pickup
            }
            disabled={is_disabled('')}
            cancelled={isRobTaskCancelled((fleet_request ?? rob)?.task_status)}
            price={
              _.isNumber(costs.delivery) || _.isNumber(costs.pickup)
                ? (costs.delivery ?? 0) + (costs.pickup ?? 0)
                : null
            }
            initialValues={costs}
            onChange={({delivery, pickup}) => {
              setCosts((prevState) => ({
                ...prevState,
                delivery,
                pickup,
              }));
            }}
            editDialog={({changes, setChanges}) => (
              <>
                <View style={[sy['p-4']]}>
                  <FormNumberInput
                    label="Brengkosten (euro)"
                    digits={2}
                    disabled={
                      !robContract?.permissions.replacement_vehicle.costs
                        .delivery
                    }
                    value={changes.delivery}
                    onChangeNumber={(number) =>
                      setChanges((prevState) => ({
                        ...prevState,
                        delivery: number,
                      }))
                    }
                  />
                </View>
                <View style={[sy['p-4'], sy['pt-0']]}>
                  <FormNumberInput
                    label="Haalkosten (euro)"
                    disabled={
                      !robContract?.permissions.replacement_vehicle.costs.pickup
                    }
                    digits={2}
                    value={changes.pickup}
                    onChangeNumber={(number) =>
                      setChanges((prevState) => ({
                        ...prevState,
                        pickup: number,
                      }))
                    }
                  />
                </View>
              </>
            )}
          />
        )}
        {[ROB_REPLACEMENT_STAGE.checkin].includes(stage) && (
          <>
            <LineItem
              icon={<LocalGasStationIcon fill="#4A4A49" />}
              title="Brandstof- en aftankkosten"
              allowed={
                robContract?.permissions.replacement_vehicle.costs.fuel ||
                robContract?.permissions.replacement_vehicle.costs.fuel_service
              }
              disabled={is_disabled('')}
              cancelled={isRobTaskCancelled(
                (fleet_request ?? rob)?.task_status,
              )}
              price={
                _.isNumber(costs.fuel) || _.isNumber(costs.fuel_service)
                  ? (costs.fuel ?? 0) + (costs.fuel_service ?? 0)
                  : null
              }
              initialValues={costs}
              onChange={({fuel, fuel_service}) => {
                setCosts((prevState) => ({
                  ...prevState,
                  fuel,
                  fuel_service,
                }));
              }}
              editDialog={({changes, setChanges}) => (
                <>
                  <View style={[sy['p-4']]}>
                    <FormNumberInput
                      label="Brandstofkosten (euro)"
                      digits={2}
                      disabled={
                        !robContract?.permissions.replacement_vehicle.costs.fuel
                      }
                      value={changes.fuel}
                      onChangeNumber={(number) =>
                        setChanges((prevState) => ({
                          ...prevState,
                          fuel: number,
                        }))
                      }
                    />
                  </View>
                  <View style={[sy['p-4'], sy['pt-0']]}>
                    <FormNumberInput
                      label="Aftankkosten (euro)"
                      disabled={
                        !robContract?.permissions.replacement_vehicle.costs
                          .fuel_service
                      }
                      digits={2}
                      value={changes.fuel_service}
                      onChangeNumber={(number) =>
                        setChanges((prevState) => ({
                          ...prevState,
                          fuel_service: number,
                        }))
                      }
                    />
                  </View>
                </>
              )}
            />
            <LineItem
              icon={<GppBadIcon fill="#4A4A49" />}
              title="Eigen risico claim"
              allowed={robContract?.permissions.replacement_vehicle.costs.claim}
              disabled={is_disabled('')}
              cancelled={isRobTaskCancelled(
                (fleet_request ?? rob)?.task_status,
              )}
              price={costs.claim}
              initialValues={costs}
              onValidate={({claim, claim_comment}) => {
                const hasError = _.isNumber(claim) && !claim_comment;
                return {
                  isValid: !hasError,
                  errors: {
                    claim_comment: hasError,
                  },
                };
              }}
              onChange={({claim, claim_comment}) => {
                setCosts((prevState) => ({
                  ...prevState,
                  claim,
                  claim_comment,
                }));
              }}
              editDialog={({changes, setChanges, errors}) => (
                <>
                  <View style={[sy['p-4']]}>
                    <FormNumberInput
                      label="Bedrag (euro)"
                      digits={2}
                      value={changes.claim}
                      onChangeNumber={(number) =>
                        setChanges((prevState) => ({
                          ...prevState,
                          claim: number,
                        }))
                      }
                    />
                  </View>
                  <View style={[sy['p-4'], sy['pt-0']]}>
                    <FormTextInput
                      label="Toelichting"
                      value={changes.claim_comment}
                      errorMessage="Dit veld is verplicht"
                      hasError={errors?.claim_comment}
                      onChangeText={(text) =>
                        setChanges((prevState) => ({
                          ...prevState,
                          claim_comment: text,
                        }))
                      }
                      inputProps={{
                        multiline: true,
                        numberOfLines: 8,
                      }}
                    />
                  </View>
                </>
              )}
            />
          </>
        )}
        <LineItem
          icon={<DescriptionIcon fill="#4A4A49" />}
          title="Overige kosten"
          allowed={robContract?.permissions.replacement_vehicle.costs.misc}
          disabled={is_disabled('')}
          cancelled={isRobTaskCancelled((fleet_request ?? rob)?.task_status)}
          price={costs.misc}
          initialValues={costs}
          onValidate={({misc, misc_comment}) => {
            const hasError = _.isNumber(misc) && !misc_comment;
            return {
              isValid: !hasError,
              errors: {
                misc_comment: hasError,
              },
            };
          }}
          onChange={({misc, misc_comment}) => {
            setCosts((prevState) => ({
              ...prevState,
              misc,
              misc_comment,
            }));
          }}
          editDialog={({changes, setChanges, errors}) => (
            <>
              <View style={[sy['p-4']]}>
                <FormNumberInput
                  label="Bedrag (euro)"
                  digits={2}
                  value={changes.misc}
                  onChangeNumber={(number) =>
                    setChanges((prevState) => ({
                      ...prevState,
                      misc: number,
                    }))
                  }
                />
              </View>
              <View style={[sy['p-4'], sy['pt-0']]}>
                <FormTextInput
                  label="Toelichting"
                  value={changes.misc_comment}
                  errorMessage="Dit veld is verplicht"
                  hasError={errors?.misc_comment}
                  onChangeText={(text) =>
                    setChanges((prevState) => ({
                      ...prevState,
                      misc_comment: text,
                    }))
                  }
                  inputProps={{
                    multiline: true,
                    numberOfLines: 8,
                  }}
                />
              </View>
            </>
          )}
        />

        {(replacement?.status === STATUS_CODES.Done ||
          (fleet_request ?? rob)?.invoicing?.status ===
            STATUS_CODES.Invoiced) && (
          <>
            <Divider />
            <View
              style={[
                sy['flex-row'],
                sy['items-center'],
                sy['justify-between'],
                sy['p-4'],
              ]}>
              <View style={[sy['flex-row'], sy['flex-1']]}>
                <View style={[sy['pr-8']]}>
                  <ReceiptIcon
                    fill={
                      !isNotInvoiced(
                        (fleet_request ?? rob)?.invoicing?.status,
                      ) && !replacement?.invoice_number
                        ? '#FF6C00'
                        : '#4A4A49'
                    }
                  />
                </View>
                <Text style={sy.mediumPlus}>
                  {isNotInvoiced((fleet_request ?? rob)?.invoicing?.status) ? (
                    <>Geen factuur</>
                  ) : (
                    <>
                      Factuur
                      {replacement.invoice_number ? (
                        <>&nbsp;&middot;&nbsp;{replacement.invoice_number}</>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Text>
              </View>
              <>
                {isNotInvoiced((fleet_request ?? rob)?.invoicing?.status) ? (
                  <></>
                ) : (
                  <Text style={sy.mediumPlus}>
                    {Format.price(invoice?.amount ?? total_amount)}
                  </Text>
                )}
              </>
            </View>
            <Divider />
            {isNotInvoiced((fleet_request ?? rob)?.invoicing?.status) ? (
              <GroupingRow title="Reden">
                <Text>
                  {
                    ROB_NOT_INVOICED_STATUS_LABELS[
                      (fleet_request ?? rob)?.invoicing?.status
                    ]
                  }
                </Text>
              </GroupingRow>
            ) : (
              !isArchived((fleet_request ?? rob)?.status) &&
              (fleet_request ?? rob)?.invoicing?.status !== 'Invoiced' && (
                <GroupingRow
                  linkStyle={[
                    {
                      color: !replacement?.invoice_number ? '#FF6C00' : null,
                    },
                  ]}
                  title={
                    <View>
                      <View style={[sy['flex-row'], sy['items-center']]}>
                        <Text>Nummer</Text>
                      </View>
                      {!replacement?.invoice_number &&
                        !invoice?.bahnkick_can_invoice &&
                        invoice?.pdf_upload && (
                          <View>
                            <Text
                              style={[sy.smallRegular, sy['text-lightgray']]}>
                              Let op: facturatie aan{' '}
                              <>{service_request.lessor}</>
                            </Text>
                          </View>
                        )}
                    </View>
                  }
                  onEditClicked={() =>
                    setEditInvoiceNumber({
                      value: replacement?.invoice_number,
                      valid: true,
                    })
                  }>
                  <>{replacement?.invoice_number ?? <>Opgeven</>}</>
                  <GroupingRowEdit
                    title="Factuur"
                    dismissable
                    cancelButton
                    onValidate={async () => {
                      if (edit_invoiceNumber.value?.length > 0) {
                        const result = await onUpdateRequest(
                          edit_invoiceNumber.value,
                        );

                        if (result) {
                          return true;
                        }

                        setEditInvoiceNumber((prevState) => ({
                          ...prevState,
                          valid: false,
                        }));

                        return false;
                      }
                    }}>
                    <View style={[sy['p-4']]}>
                      <FormTextInput
                        label="Nummer"
                        value={edit_invoiceNumber?.value}
                        hasError={edit_invoiceNumber?.valid === false}
                        errorMessage="Factuurnummer opslaan is mislukt. Controleer het factuurnummer en probeer het opnieuw."
                        onEndEditing={(value) => {
                          setEditInvoiceNumber((prevState) => ({
                            ...prevState,
                            value,
                            valid: true,
                          }));
                        }}
                      />
                    </View>
                  </GroupingRowEdit>
                </GroupingRow>
              )
            )}
            {!isNotInvoiced((fleet_request ?? rob)?.invoicing?.status) &&
              invoice?.components && (
                <>
                  <GroupingRow
                    title={
                      <View>
                        <Text>Opdracht</Text>
                        <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                          Exclusief BTW
                        </Text>
                      </View>
                    }>
                    <Text style={sy.smallRegular}>
                      {Format.price(invoice.amount_approved)}
                    </Text>
                  </GroupingRow>
                  {Format.round(
                    invoice.amount_approved -
                      (invoice?.amount_approved_discount ?? 0) -
                      (invoice?.amount ?? total_amount),
                  ) > 0 && (
                    <GroupingRow
                      title={
                        <View>
                          <Text>Inhouding</Text>
                        </View>
                      }>
                      <Text style={sy.smallRegular}>
                        {Format.price(
                          invoice.amount_approved -
                            (invoice?.amount_approved_discount ?? 0) -
                            (invoice?.amount ?? total_amount),
                        )}
                      </Text>
                      <GroupingRowEdit
                        title="Inhouding"
                        titleIcon={<DiscountIcon fill="#4a4a49" />}
                        titleStyle={[sy['ml-4'], sy['mr-4']]}>
                        <ScrollView
                          contentContainerStyle={{
                            maxHeight:
                              dimensions.window.height -
                              INVOICE_COMPONENTS_HEADER_HEIGHT_IN_PX -
                              INVOICE_COMPONENTS_FOOTER_HEIGHT_IN_PX,
                          }}>
                          {Object.keys(invoice.components)
                            .filter(
                              (type) => invoice.components[type].amount > 0,
                            )
                            .sort(
                              (a, b) =>
                                INVOICE_DISCOUNT_ORDER.indexOf(a) -
                                INVOICE_DISCOUNT_ORDER.indexOf(b),
                            )
                            .map((type) => {
                              const item = invoice.components[type];
                              return (
                                <GroupingRow
                                  key={type}
                                  title={
                                    <View>
                                      <Text>
                                        {INVOICE_DISCOUNT_LABELS[item.type] ?? (
                                          <>Korting</>
                                        )}
                                      </Text>
                                      <Text
                                        style={[
                                          sy.smallRegular,
                                          sy['text-lightgray'],
                                        ]}>
                                        <>
                                          {Format.number(
                                            item.discount * 100,
                                            1,
                                            false,
                                            {
                                              minimumFractionDigits: 0,
                                            },
                                          )}
                                          %
                                        </>
                                        {!INVOICE_DISCOUNT_LABELS[item.type] &&
                                          item.lessor && (
                                            <>
                                              &nbsp;&middot;&nbsp;{item.lessor}
                                            </>
                                          )}
                                        {item.subject && (
                                          <>
                                            &nbsp;&middot;&nbsp;{item.subject}
                                          </>
                                        )}
                                      </Text>
                                    </View>
                                  }>
                                  <Text style={sy.smallRegular}>
                                    {Format.price(item.amount)}
                                  </Text>
                                </GroupingRow>
                              );
                            })}
                        </ScrollView>
                      </GroupingRowEdit>
                    </GroupingRow>
                  )}
                  {invoice?.amount_approved_discount > 0 && (
                    <GroupingRow
                      title={
                        <View>
                          <Text>Korting</Text>
                        </View>
                      }>
                      <Text style={sy.smallRegular}>
                        {Format.price(invoice?.amount_approved_discount)}
                      </Text>
                      <GroupingRowEdit
                        title="Korting"
                        titleIcon={<DiscountIcon fill="#4a4a49" />}
                        titleStyle={[sy['ml-4'], sy['mr-4']]}>
                        <ScrollView
                          contentContainerStyle={{
                            maxHeight:
                              dimensions.window.height -
                              INVOICE_COMPONENTS_HEADER_HEIGHT_IN_PX -
                              INVOICE_COMPONENTS_FOOTER_HEIGHT_IN_PX,
                          }}>
                          {Object.keys(invoice.components)
                            .filter(
                              (type) =>
                                type === 'SLA_APPROVED_INVOICE_DISCOUNT' &&
                                invoice.components[type].amount > 0,
                            )
                            .sort(
                              (a, b) =>
                                INVOICE_DISCOUNT_ORDER.indexOf(a) -
                                INVOICE_DISCOUNT_ORDER.indexOf(b),
                            )
                            .map((type) => {
                              const item = invoice.components[type];
                              return (
                                <GroupingRow
                                  key={type}
                                  title={
                                    <View>
                                      <Text>
                                        {INVOICE_DISCOUNT_LABELS[item.type] ?? (
                                          <>Korting</>
                                        )}
                                      </Text>
                                      <Text
                                        style={[
                                          sy.smallRegular,
                                          sy['text-lightgray'],
                                        ]}>
                                        <>
                                          {Format.number(
                                            item.discount * 100,
                                            1,
                                            false,
                                            {
                                              minimumFractionDigits: 0,
                                            },
                                          )}
                                          %
                                        </>
                                        {item.lessor && (
                                          <>
                                            &nbsp;&middot;&nbsp;
                                            {item.lessor}
                                          </>
                                        )}
                                        {item.subject && (
                                          <>
                                            &nbsp;&middot;&nbsp;
                                            {item.subject}
                                          </>
                                        )}
                                      </Text>
                                    </View>
                                  }>
                                  <Text style={sy.smallRegular}>
                                    {Format.price(item.amount)}
                                  </Text>
                                </GroupingRow>
                              );
                            })}
                        </ScrollView>
                      </GroupingRowEdit>
                    </GroupingRow>
                  )}
                </>
              )}
            <GroupingRow
              title={
                <View>
                  <Text>Totaal</Text>
                  <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                    Exclusief BTW
                  </Text>
                </View>
              }>
              <Text style={sy.smallRegular}>
                {Format.price(invoice?.amount ?? total_amount)}
              </Text>
            </GroupingRow>

            {(invoice?.bahnkick_can_invoice || invoice?.pdf_available) &&
              replacement?.invoice_number && (
                <GroupingRow
                  title={
                    <PdfViewerLink
                      invoice={invoice}
                      request_id={replacement.request_id}
                    />
                  }>
                  <FileOpenIcon />
                </GroupingRow>
              )}
            {isArchived((fleet_request ?? rob)?.status) ? (
              <></>
            ) : (
              <>
                {(fleet_request ?? rob)?.invoicing?.status ===
                  'ReadyForInvoicing' && (
                  <View style={[sy['p-4'], sy['flex-row']]}>
                    <BlockIcon />
                    <Link
                      style={[sy['pl-8']]}
                      onPress={() => setShowNoInvoiceDialog(true)}>
                      Niet factureren
                    </Link>
                  </View>
                )}
                {isNotInvoiced((fleet_request ?? rob)?.invoicing?.status) && (
                  <View style={[sy['p-4'], sy['flex-row']]}>
                    <ReceiptIcon />
                    <Link style={[sy['pl-8']]} onPress={onRequest}>
                      Wel factureren
                    </Link>
                  </View>
                )}
              </>
            )}
          </>
        )}
      </ScrollView>
      <Grouping
        icon={
          <View>
            <ChatIcon fill="#4A4A49" />
            <Badge
              theme={{colors: {notification: '#ff6c00'}}}
              visible={!readLastMessage}
              style={{position: 'absolute', top: 0, right: -2}}
              size={10}
            />
          </View>
        }
        header="Berichten"
        onExpand={() => setReadLastMessage(true)}
        options={{switchChevrons: true}}>
        <LessorMessage
          key={replacement?.last_message?.message_id}
          lessor={service_request.lessor}
          request_id={replacement?.request_id}
          {...(replacement?.last_message ?? {
            content: '(nog geen berichten)',
          })}
        />
        {is_disabled() ? (
          <></>
        ) : (
          <>
            <Divider style={sy['bg-gray95']} />
            <InputChat
              lessor={service_request.lessor}
              message={pendingMessage}
              onChange={setPendingMessage}
              onSend={onSendChatMessage}
            />
          </>
        )}
      </Grouping>
      <Footer
        disabled={
          extension_comment_missing ||
          is_disabled() ||
          (isFleet &&
            (!_.isNumber(rentalCharge) ||
              !_.isNumber(kmSurcharge) ||
              !_.isNumber(freeKilometrage) ||
              !car?.license ||
              !_.isNumber(kilometrage)))
        }
        status={replacement?.status}
        hasSLA={
          _.isNumber(rentalCharge) &&
          _.isNumber(kmSurcharge) &&
          _.isNumber(freeKilometrage)
        }
        car={car}
        kilometrage={kilometrage}
        invoiceNumber={replacement?.invoice_number}
        rob={rob}
        fleet_request={fleet_request}
        onRequest={onRequest}
        onFinish={onFinish}
        onInvoice={onInvoice}
      />
      <RentalClassActionsheet
        visible={showEditRentalClass}
        request_id={replacement?.request_id}
        rental_class={rental_class}
        contract_rental_class={contract_rental_class}
        rental_car_class={car?.rental_class}
        fuel={fuel}
        onChange={(value) => {
          setRentalClass(value);
          setShowEditRentalClass(false);
        }}
        onClose={() => setShowEditRentalClass(false)}
      />
      <RentalCarActionsheet
        visible={showEditCar}
        dealer_id={replacement?.dealer_id}
        license={car?.license}
        fuel={fuel}
        rental_class={rental_class}
        contract_rental_class={contract_rental_class}
        onChange={async (car) => {
          let rental_class = contract_rental_class;
          if (car?.rental_class && contract_rental_class) {
            const {result: sla} =
              await serviceRequestApi.sla_replacement_vehicle(
                replacement.request_id,
                car?.fuel,
                car?.rental_class,
                car?.is_estate,
                car?.gearbox,
              );

            const rental_class_charge =
              sla?.[ROB_REPLACEMENT_RENTAL_CHARGE_CODE];
            const contract_rental_class_charge =
              sla?.contract?.[ROB_REPLACEMENT_RENTAL_CHARGE_CODE];

            if (
              _.isNumber(rental_class_charge?.amount) &&
              (!_.isNumber(contract_rental_class_charge?.amount) ||
                rental_class_charge?.amount <
                  contract_rental_class_charge?.amount)
            ) {
              rental_class = car.rental_class;
            }
          }

          setCar(car);
          setFuel(car?.fuel);
          setRentalClass(rental_class);
        }}
        onClose={() => setShowEditCar(false)}
      />
      {showNoInvoiceInfoDialog && (
        <Dialog
          visible={true}
          title="Gereed zonder factuur"
          buttons={[
            {
              text: 'OK',
              textStyle: {color: '#972727'},
              onPress: () => setShowNoInvoiceInfoDialog(false),
            },
          ]}>
          <View style={[sy['flex-row'], sy['py-4']]}>
            <View style={[{flexShrink: 0}, sy['pr-4']]}>
              <AssignmentTurnedInIcon
                height="24px"
                width="24px"
                fill="#828282"
              />
            </View>
            <Text>
              Opdracht niet gefactureerd. Toch wel factureren? Dit is mogelijk
              tot aan archivering.
            </Text>
          </View>
        </Dialog>
      )}
      {showNoInvoiceDialog && (
        <Dialog
          visible={true}
          title="Reden geen factuur"
          buttons={[
            {
              text: 'Annuleren',
              onPress: () => setShowNoInvoiceDialog(false),
            },
            {
              text: 'Opslaan',
              textStyle: {color: '#972727'},
              onPress: onChangeInvoiceStatus,
            },
          ]}
          options={{noPaddingContent: true}}>
          <View>
            <Pressable
              style={[sy['flex-row']]}
              onPress={() =>
                setInvoiceStatus(
                  'WillNotBeInvoicedForWarrantyOrLeniencyReasons',
                )
              }>
              <View style={[sy['p-4'], sy['pr-8']]}>
                <RadioButton
                  checked={
                    invoiceStatus ===
                    'WillNotBeInvoicedForWarrantyOrLeniencyReasons'
                  }
                />
              </View>
              <View
                style={[
                  sy['flex-row'],
                  sy['flex-1'],
                  sy['justify-between'],
                  sy['py-4'],
                  sy['pr-4'],
                  sy['border-b'],
                  sy['border-lightgray'],
                ]}>
                <Text>Garantie of coulance</Text>
              </View>
            </Pressable>
            <Pressable
              style={[sy['flex-row']]}
              onPress={() => setInvoiceStatus('Invoiced')}>
              <View style={[sy['p-4'], sy['pr-8']]}>
                <RadioButton checked={invoiceStatus === 'Invoiced'} />
              </View>
              <View
                style={[
                  sy['flex-row'],
                  sy['flex-1'],
                  sy['justify-between'],
                  sy['py-4'],
                  sy['pr-4'],
                  sy['border-b'],
                  sy['border-lightgray'],
                ]}>
                <Text>Al gefactureerd</Text>
              </View>
            </Pressable>
            <Pressable
              style={[sy['flex-row']]}
              onPress={() => setInvoiceStatus('WillNotBeInvoiced')}>
              <View style={[sy['p-4'], sy['pr-8']]}>
                <RadioButton checked={invoiceStatus === 'WillNotBeInvoiced'} />
              </View>
              <View
                style={[
                  sy['flex-row'],
                  sy['flex-1'],
                  sy['justify-between'],
                  sy['py-4'],
                  sy['pr-4'],
                  sy['border-b'],
                  sy['border-lightgray'],
                ]}>
                <Text>Niet gefactureerd</Text>
              </View>
            </Pressable>
          </View>
        </Dialog>
      )}

      <Dialog
        title="Bericht opslaan?"
        visible={showSaveMessageDialog}
        buttons={[
          {
            text: 'Annuleren',
            onPress: () => setShowSaveMessageDialog(false),
          },
          {
            text: 'Opslaan',
            onPress: async () => {
              await onSendChatMessage();
              setShowSaveMessageDialog(false);
            },
          },
        ]}>
        <View style={[sy['py-4']]}>
          <Text>Het getypte bericht is nog niet opgeslagen.</Text>
        </View>
      </Dialog>

      {showInvoicePdfUploadDialog && (
        <InvoiceUploadDialog
          visible={true}
          lessor={service_request?.lessor}
          onDismiss={() => setShowInvoicePdfUploadDialog(false)}
          onOK={async (data) => {
            setShowInvoicePdfUploadDialog(false);
            await InvoiceServiceRequest(data);
          }}
        />
      )}
    </>
  );
};

export default withDimensions(ReplacementCar);
